/* eslint-disable new-cap */
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-export-i18n";
import { styled, alpha, Theme, CSSObject } from "@mui/material/styles";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import {
  AccountBoxOutlined,
  Add,
  Event,
  ForumOutlined,
  Logout,
  SvgIconComponent,
  Tune,
} from "@mui/icons-material";

import { signOut } from "@cognito";
import Link from "@utilities/Link";
import SettingsDialog from "../SettingsDialog";
import DrawerHeader from "./drawerHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { authStore, utilityStore, meetingsStore } from "@state/store";
import theme from "@components/VisionableTheme";
import { debounce } from "@utilities";

const drawerWidth = 250;
const small = true;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const MiniDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .MuiPaper-root": {
    background: theme.palette.primary.main,
  },
  "& .MuiTypography-root, & .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiDivider-root": {
    background: alpha("#fff", 0.12),
  },
}));

const MobileDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),

  "& .MuiPaper-root": {
    background: theme.palette.primary.main,
  },
  "& .MuiTypography-root, & .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiDivider-root": {
    background: alpha("#fff", 0.12),
  },
}));

// type linkItemProps
type linkItemProps = {
  name: string;
  translatedText?: string;
  href: string;
  onClick?: () => void;
  icon?: SvgIconComponent;
};

const NavList = (linkList: linkItemProps[], open: boolean) => {
  const { t } = useTranslation();
  const theme = useTheme();
  //STATE
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  //GLOBAL STATE
  const { navbarOpen, setNavbarOpen } = utilityStore();
  //BREAKPOINTS
  const matches = useMediaQuery("(max-width:900px)");

  const handleRouteChange = (link: any, e: any) => {
    if (matches) {
      setNavbarOpen(false);
    }
    link.onClick && link.onClick(e);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  MANAGES CHANGES  ON SCREEN RESIZE
  ///
  ///////////////////////////////////////////////////////////////

  // FIRE ON REIZE EVENT
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  // SETUP LISTENER ON INIT
  useEffect(() => {
    window.addEventListener("resize", debounce(handleResize, 300), false);
  }, []);
  // CHECK FOR RESIZE BREAKPOINTS
  useEffect(() => {
    if (dimensions.width <= 900) {
      setNavbarOpen(false);
    }
  }, [dimensions]);

  return linkList.map((link: linkItemProps, index: number) => (
    <Link
      key={index}
      href={`/${link.href}`}
      title={link.translatedText && t(`${link.translatedText}`)}
      onClick={(e) => {
        handleRouteChange(link, e);
      }}
      sx={{
        "&.active .MuiButtonBase-root": {
          background: theme.palette.secondary.main,
        },
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {link.icon ? <link.icon /> : <Add fontSize="small" />}
        </ListItemIcon>
        <ListItemText sx={{ opacity: open ? 1 : 0 }}>
          {link.translatedText ? t(`${link.translatedText}`) : link.name}
        </ListItemText>
      </ListItemButton>
    </Link>
  ));
};

const NavbarDrawer = () => {
  const router = useRouter();
  const {
    settingsDialogOpen,
    setSettingsDialogOpen,
    navbarOpen,
    setNavbarOpen,
    signOutModalOpen,
    setSignOutModalOpen,
  } = utilityStore();

  const toggleSettingsDialog = () => {
    setSettingsDialogOpen(!settingsDialogOpen);
  };

  const navigationLinks = [
    {
      name: "Meetings",
      translatedText: "Dashboard.Meetings",
      href: "meetings",
      icon: Event,
    },
    {
      name: "Messages",
      translatedText: "Dashboard.Messages",
      href: "messages",
      icon: ForumOutlined,
    },
    {
      name: "Contacts",
      translatedText: "Dashboard.Contacts",
      href: "contacts",
      icon: AccountBoxOutlined,
    },
  ];

  const navigationLinksBottom = [
    {
      name: "Settings",
      href: "",
      icon: Tune,
      onClick: toggleSettingsDialog,
    },
  ];

  return (
    <>
      <Box component="nav" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        {/* DESKTOP DRAWER  */}

        <MiniDrawer
          sx={{ display: { xs: "none", md: "block" } }}
          variant="permanent"
          open={navbarOpen}
        >
          <DrawerHeader sx={{ pl: 2.5, justifyContent: "flex-start" }}>
            <img
              width={32}
              height={32}
              src="/visionable-icon.svg"
              alt="Visionable icon logo"
            />
          </DrawerHeader>
          <Divider />
          <List>{NavList(navigationLinks, navbarOpen)}</List>
          <Divider />
          <List sx={{ mt: "auto" }}>
            {NavList(navigationLinksBottom, navbarOpen)}
          </List>
        </MiniDrawer>

        {/* MOBILE DRAWER */}

        <MobileDrawer
          variant="temporary"
          // BackdropProps={{ invisible: true }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
          anchor={"left"}
          open={navbarOpen}
          onClose={(_, reason) =>
            reason === "backdropClick" && setNavbarOpen(false)
          }
        >
          <>
            <DrawerHeader sx={{ pl: 2.5, justifyContent: "flex-start" }}>
              <img
                width={32}
                height={32}
                src="/visionable-icon.svg"
                alt="Visionable icon logo"
              />
            </DrawerHeader>
            <Divider />
            <List>{NavList(navigationLinks, navbarOpen)}</List>
            <Divider />
            <List sx={{ mt: "auto" }}>
              {NavList(navigationLinksBottom, navbarOpen)}
            </List>
          </>
        </MobileDrawer>
      </Box>
      <SettingsDialog />
    </>
  );
};

export default NavbarDrawer;
