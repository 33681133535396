import { useEffect } from "react";
import { useRouter } from "next/router";
import { styled } from "@mui/material/styles";
import { Box, Toolbar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { setLocalStorage, getLocalStorage, usersDrawerKey } from "@utilities";
import {
  ChevronLeft,
  ChevronRight,
  HelpOutline,
  NotificationsNone,
} from "@mui/icons-material";
import { userStore, utilityStore } from "@state/store";
import SearchApp from "./SearchApp";
import UserProfileAvatar from "@components/UserProfileAvatar";
import VisAvatar from "@components/Avatar";

const drawerWidth = 250;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  "& .MuiSvgIcon-root": { color: "white" },
}));

const TopAppBar = () => {
  const router = useRouter();
  const { pathname } = router;

  const { navbarOpen, setNavbarOpen } = utilityStore();
  const { awsAmplifyUser, setAwsAmplifyUser } = userStore();

  const user_id = awsAmplifyUser?.username;
  const drawerStorageKey = usersDrawerKey(user_id);

  const toggleDrawer = () => {
    setLocalStorage(drawerStorageKey, !navbarOpen);
    setNavbarOpen(!navbarOpen);
  };

  return (
    <AppBar
      // position="fixed"
      elevation={0}
      open={navbarOpen}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "&.MuiToolbar-root ": {
            pl: navbarOpen ? 1 : 2,
          },
        }}
      >
        <Box sx={{ display: navbarOpen ? "none" : "block" }}>
          <img
            width={32}
            height={32}
            src="/visionable-icon.svg"
            alt="Visionable icon logo"
          />
        </Box>

        <Box sx={{ display: "flex", flex: 3, alignItems: "center" }}>
          <Box p={1}>
            <IconButton onClick={toggleDrawer}>
              {navbarOpen ? (
                <ChevronLeft sx={{ display: { xs: "none", md: "block" } }} />
              ) : (
                <ChevronRight sx={{ display: { xs: "none", md: "block" } }} />
              )}
            </IconButton>
          </Box>

          {pathname === "/contacts" && <SearchApp />}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton>
            <NotificationsNone />
          </IconButton>
          <IconButton>
            <HelpOutline />
          </IconButton>
          <IconButton
            sx={{ display: { xs: "block", md: "none" } }}
            onClick={(e) => {
              toggleDrawer();
            }}
          >
            <MenuIcon
              sx={{ display: navbarOpen ? "block" : "block" }}
              fontSize="large"
            />
          </IconButton>
          <UserProfileAvatar />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopAppBar;
