import { useEffect } from "react";
import { NextPage } from "next";
import Head from "next/head";
import withAuth from "@utilities/withAuth";
import Box from "@mui/material/Box";
import NavbarDrawer from "@components/NavbarDrawer";
import TopAppBar from "@components/TopAppBar";
import DrawerHeader from "@components/NavbarDrawer/drawerHeader";
import LocaleState from "@components/LocaleState";
import { getUserPool } from "@requests/Authentication";
import { userStore, authStore } from "@state/store";
import { getCurrentUserInfo } from "@cognito";
import { CognitoUser } from "types";
import { useRouter } from "next/router";
import { DialogMFA } from "@components/DialogMFA";
import { LogoutConfirmation } from "@components/DialogLogoutConfirmation";

export type VTDashboard = {
  children?: any;
};

const Dashboard: NextPage = ({ children }: VTDashboard) => {
  // GLOBAL STATE
  const {
    accountUsers,
    setAccountUsers,
    awsAmplifyUser,
    setAwsAmplifyUser,
    user,
    setUser,
  } = userStore();
  const { isSignedIn, setIsSignedIn } = authStore();

  //ROUTER
  const router = useRouter();

  useEffect(() => {
    try {
      if (isSignedIn) {
        // Get current user information
        getCurrentUserInfo()
          .then((user: CognitoUser) => {
            setAwsAmplifyUser(user);
          })
          .catch((error: any) => {
            console.log(error);
            setAwsAmplifyUser({} as CognitoUser);
          });
        // Get current user Pool information
        getUserPool()
          .then((users: any) => {
            setAccountUsers(users);
          })
          .catch((error: any) => {
            console.log(error);
            setAccountUsers([]);
          });
      } else {
        setAwsAmplifyUser({} as CognitoUser);
        setAccountUsers([]);
        //setClient(undefined);
      }
    } catch (error) {
      console.log(error);
    }

    //console.debug("Handling isSignedIn state update...");
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Visionable</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Box sx={{ display: "flex" }}>
        <LogoutConfirmation />
        <DialogMFA />

        <TopAppBar />

        <NavbarDrawer />
        <Box
          component="main"
          sx={{ flexGrow: 1, height: "100vh", overflow: "hidden" }}
        >
          <DrawerHeader />
          {children}
        </Box>
        <LocaleState />
      </Box>
    </>
  );
};

export default withAuth(Dashboard);
