import { useContext, useState, useEffect, ReactElement } from "react";
import { ChatContext } from "@chat";
import * as ChatTypes from "@chat/types";
import { Avatar, AvatarProps, Badge, BadgeProps, styled } from "@mui/material";
import { AccessTime, Check, Remove } from "@mui/icons-material";
import theme from "@components/VisionableTheme";

// list of colors to generate avatar backgrounds from
const colorList = [
  { name: "Teal", hex: "#477d94", rgb: hexToRgb("#477d94") },
  { name: "Deep Purple", hex: "#843d6a", rgb: hexToRgb("#843d6a") },
  { name: "Vivid Pink", hex: "#C638A9", rgb: hexToRgb("#C638A9") },
  { name: "Light Blue", hex: "#699BF8", rgb: hexToRgb("#699BF8") },
  { name: "Purple", hex: "#652BB3", rgb: hexToRgb("#652BB3") },
  { name: "Orange", hex: "#EF6400", rgb: hexToRgb("#EF6400") },
] as {
  name: string;
  hex: string;
  rgb: { r: number; g: number; b: number };
}[];

// generate hex color from a string
const stringToColor = (string: string | undefined) => {
  if (!string) return;

  /** generate color from name */
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

function hexToRgb(hex: string) {
  const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
  return (
    m && {
      r: parseInt(m[1], 16),
      g: parseInt(m[2], 16),
      b: parseInt(m[3], 16),
    }
  );
}

const getNearestColorInList = (
  seed: string,
  list: {
    name: string;
    hex: string;
    rgb: {
      r: number;
      g: number;
      b: number;
    } | null;
  }[]
) => {
  if (!seed) return;

  let nearestColor;
  let minDistance = Number.MAX_SAFE_INTEGER;

  const c1 = hexToRgb(seed); // rgb value of generated color

  // find nearest color from generated one in color list
  for (let index = 0; index < list.length; index++) {
    const color = list[index].hex;
    const c2 = list[index].rgb;
    const distance =
      c1 && c2
        ? Math.sqrt(
            Math.pow(c2.r - c1.r, 2) +
              Math.pow(c2.g - c1.g, 2) +
              Math.pow(c2.b - c1.b, 2)
          )
        : minDistance;

    if (distance < minDistance) {
      minDistance = distance;
      nearestColor = color;
    }
  }

  return nearestColor;
};

const getBackgroundColor = (avatarData: ChatTypes.Contact) => {
  const colorSeed = stringToColor(
    avatarData?.user_id ||
      avatarData?.display_name ||
      avatarData?.full_name ||
      avatarData?.name ||
      `${avatarData?.first_name} ${avatarData?.last_name}`
  );
  if (colorList !== null && colorSeed) {
    const bg = colorSeed && getNearestColorInList(colorSeed, colorList);
    return bg;
  }
};

const avatarInitials = (avatarData: ChatTypes.Contact | null) => {
  if (!avatarData) return;

  const initials = `${avatarData.first_name.charAt(
    0
  )}${avatarData.last_name.charAt(0)}`;
  return {
    children: initials,
  };
};

interface StatusBadgeProps extends BadgeProps {
  status?: ChatTypes.Presence;
  size?: "small" | "med" | "large";
}

export const StatusBadge = ({
  size = "small",
  sx = [],
  status,
  children,
  invisible,
  ...rest
}: StatusBadgeProps) => {
  let backgroundColor: string | undefined;
  let badgeIcon: ReactElement = <></>;

  switch (status) {
    case "available":
      backgroundColor = theme.palette.success.main;
      badgeIcon = <Check />;
      break;

    case "away":
      backgroundColor = theme.palette.warning.main;
      badgeIcon = <AccessTime />;
      break;

    case "busy":
      backgroundColor = theme.palette.error.main;
      break;

    case "do-not-disturb":
      backgroundColor = theme.palette.error.main;
      badgeIcon = <Remove />;
      break;

    case "in-meeting":
      backgroundColor = theme.palette.secondary.light;
      break;

    case "offline":
      backgroundColor = theme.palette.grey[500];
      break;

    default:
      backgroundColor = "white";
      break;
  }

  const badgeSize = {
    small: 20,
    med: 26,
    large: 32,
  };

  const fontSize = {
    small: "0.8rem",
    med: "1rem",
    large: "1.5rem",
  };

  const styles = {
    "& .MuiBadge-badge": {
      p: 0,
      background: invisible ? "transparent" : backgroundColor,
      width: badgeSize[size],
      height: badgeSize[size],
      borderRadius: "50%",
      border: invisible ? "none" : "2px solid white",
      boxShadow: invisible ? 0 : 1,
    },
    "& .MuiSvgIcon-root": {
      color: "white",
      fontSize: fontSize[size],
    },
  };

  return (
    <Badge
      sx={{
        ...styles,
        ...sx,
      }}
      badgeContent={badgeIcon}
      {...rest}
    >
      {children}
    </Badge>
  );
};

interface VisAvatarProps extends AvatarProps {
  userID?: string;
  size?: "small" | "med" | "large";
}

const VisAvatar = ({
  userID,
  size = "small",
  sx = [],
  ...rest
}: VisAvatarProps) => {
  const chat = useContext(ChatContext);
  const [avatarData, setAvatarData] = useState<ChatTypes.Contact>({
    display_name: "",
    first_name: "",
    last_name: "",
    _jid: "",
    user_id: "",
    full_name: "",
    name: "",
    email: "",
    avatar: "",
    presence: "offline",
    status: "",
    contact: false,
  });
  const [status, setStatus] = useState<ChatTypes.Presence | undefined>(
    undefined
  );

  // Set initials
  useEffect(() => {
    const updateAvatarData = () => {
      const isCurrentUser = chat.currentUser?.user_id === userID;
      // TODO: Custom statuses & presence
      if (isCurrentUser && chat.currentUser) {
        setAvatarData(chat.currentUser);
        setStatus(chat.currentUser?.presence);
      } else if (userID) {
        setAvatarData(chat.contacts[userID]);
        setStatus(chat.contacts[userID]?.presence);
      }
    };
    updateAvatarData();
  }, [userID, chat.contacts, chat.currentUser]);

  const avatarFontSize = {
    small: undefined,
    med: 28,
    large: 42,
  };

  const avatarSize = {
    small: undefined,
    med: 64,
    large: 90,
  };

  const badgeTransform = {
    small: "scale(0.75) translate(5px, 5px)",
    med: "scale(0.8) translate(2px, 2px)",
    large: "scale(0.8) translate(-1px, -1px)",
  };

  return (
    <div>
      <StatusBadge
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        status={status}
        size={size}
        className="Avatar-badge"
        sx={{
          "&.Avatar-badge .MuiBadge-badge": {
            transform: badgeTransform[size],
          },
        }}
        invisible={
          !!chat.contacts[avatarData?.user_id]?.contact ||
          chat.currentUser?.user_id === userID
            ? false
            : true
        }
      >
        <Avatar
          src={avatarData?.avatar}
          sx={{
            bgcolor: getBackgroundColor(avatarData),
            width: avatarSize[size],
            height: avatarSize[size],
            fontSize: avatarFontSize[size],
            ...sx,
          }}
          {...avatarInitials(avatarData)}
          {...rest}
        />
      </StatusBadge>
    </div>
  );
};

export default VisAvatar;
