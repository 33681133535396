//SUB_COMPONENTS
import Mfa from "@components/SettingsDialog/SettingsContent/SettingsTabs/SubComponents/Privacy/Mfa";
// MUI COMPONENTS
import { Stack, Typography } from "@mui/material";

const SettingsGeneral = () => {
  return (
    <Stack sx={{ py: 2 }}>
      <>
        <Typography variant="h4"> create me please</Typography>
      </>
    </Stack>
  );
};

export default SettingsGeneral;
