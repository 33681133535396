import { FC, useState } from "react";
import { useTranslation } from "next-export-i18n";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";

/** Settings Content components */
import SettingsGeneral from "./SettingsTabs/general";
import SettingsAudio from "./SettingsTabs/audio";
import SettingsVideo from "./SettingsTabs/video";
import SettingsMeetings from "./SettingsTabs/meetings";
import SettingsSharing from "./SettingsTabs/sharing";
import SettingsNotifications from "./SettingsTabs/notifications";
import SettingsPrivacy from "./SettingsTabs/privacy";
import SettingsAccessibility from "./SettingsTabs/accessibility";
import SettingsRecording from "./SettingsTabs/recording";
import SettingsAbout from "./SettingsTabs/about";
import styles from "./settings-content.module.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ height: "100%", overflow: "auto" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabsList = [
  {
    name: "General",
    translatedText: "Settings.General",
    component: SettingsGeneral,
  },
  {
    name: "Audio",
    translatedText: "Settings.Audio",
    component: SettingsAudio,
  },
  {
    name: "Video",
    translatedText: "Settings.Video",
    component: SettingsVideo,
  },
  {
    name: "Meetings",
    translatedText: "Settings.Meetings",
    component: SettingsMeetings,
  },
  {
    name: "Sharing",
    translatedText: "Settings.Sharing",
    component: SettingsSharing,
  },
  {
    name: "Notifications",
    translatedText: "Settings.Notifications",
    component: SettingsNotifications,
  },
  {
    name: "Privacy",
    translatedText: "Settings.Privacy",
    component: SettingsPrivacy,
  },
  {
    name: "Recording",
    translatedText: "Settings.Recording",
    component: SettingsRecording,
  },
  {
    name: "Accessibility",
    translatedText: "Settings.Accessibility",
    component: SettingsAccessibility,
  },
  {
    name: "About",
    translatedText: "Settings.About",
    component: SettingsAbout,
  },
];

type tabListProps = {
  name: string;
  translatedText?: string;
  component: FC; // React component
};

const SettingsContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      className={styles[`settings-content_layout`]}
      sx={{ bgcolor: "background.paper" }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={styles[`settings-tab-list`]}
        TabIndicatorProps={{
          style: {
            width: "60px",
            height: "2px",
            transform: "translate(-145px, 48px)",
            background: theme.palette.secondary.main,
          },
        }}
      >
        {
          /** settings side navbar */
          tabsList.map((tab: tabListProps, index: number) => (
            <Tab
              key={index}
              label={tab.translatedText ? t(`${tab.translatedText}`) : tab.name}
              {...a11yProps(index)}
              className={styles[`settings-tab`]}
              sx={{
                textTransform: "unset",
                alignItems: "flex-start",
                px: 2.5,
                py: 2,
                "&.Mui-selected": {
                  fontWeight: "bold",
                },
                "& .MuiTouchRipple-child": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
            />
          ))
        }
      </Tabs>
      <Box className={styles[`settings-content`]}>
        {
          /** settings content */
          tabsList.map((tab: tabListProps, index: number) => (
            <TabPanel key={index} index={index} value={value}>
              <tab.component />
            </TabPanel>
          ))
        }
      </Box>
    </Box>
  );
};

export default SettingsContent;
