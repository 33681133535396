//SUB_COMPONENTS
import Mfa from "@components/SettingsDialog/SettingsContent/SettingsTabs/SubComponents/Privacy/Mfa";
import Password from "@components/SettingsDialog/SettingsContent/SettingsTabs/SubComponents/Privacy/Password";
// MUI COMPONENTS
import { Stack, Typography } from "@mui/material";

const SettingsPrivacy = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4">Password</Typography>
      <Password />
      <Typography variant="h4">Multi Factor Authentication (MFA)</Typography>
      <Mfa />
    </Stack>
  );
};

export default SettingsPrivacy;
