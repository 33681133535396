import { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

export type VTSnackbarComponent = {
  message: string;
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
  severity: "success" | "info" | "warning" | "error";
  open: boolean;
  setOpen: (open: boolean) => void;
};

const SnackbarComponent = ({
  message,
  vertical,
  horizontal,
  severity,
  open,
  setOpen,
}: VTSnackbarComponent) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        autoHideDuration={5000}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          iconMapping={{
            success: (
              <CheckIcon
                sx={{
                  color: " #008746",
                }}
              />
            ),
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarComponent;

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      sx={{
        borderLeft: "3px solid #008746",
        width: "300px",
        height: "48px",
        borderRadius: "3px",
        color: "black",
        background: "rgba(0, 135, 70, .1)",
      }}
    />
  );
});
