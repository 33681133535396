import _DisplayName from "@components/DisplayName";
import { Box } from "@mui/material";
import { userStore } from "@state/store";
import PresenceSelect from "./PresenceSelect";
import StatusMessages from "./StatusMessages";

const PopoverContent = () => {
  const { profileOpen, showStatusCard } = userStore();

  return (
    <Box sx={{ width: "375px" }}>
      {showStatusCard ? <StatusMessages /> : <PresenceSelect />}
    </Box>
  );
};

export default PopoverContent;
