///////////////////////////////////////////////////////////////
///
///  COMPONENT DYSPLAYING ABOUT SECTION
///
///////////////////////////////////////////////////////////////

import { Stack, Box, Typography, Link } from "@mui/material";
import { useRouter } from "next/router";
import { utilityStore } from "@state/store";

const SettingsAbout = () => {
  //GLOBAL STATE
  const { setSettingsDialogOpen } = utilityStore();

  // ROUTER
  const router = useRouter();

  // VERSIONING NEED TO CONNECT TO ENV VAR
  const version = "3.0.0.0";

  // REDIRECT TO THIRD PARTY MODULES
  const handleRedirect = () => {
    router.push("/thirdparty");
    setSettingsDialogOpen(false);
  };

  return (
    <Stack>
      <Box sx={{ p: 1 }}></Box>
      <Typography variant="h4">Visionable</Typography>
      <Typography>Version: {version}</Typography>

      <Box sx={{ p: 1 }}></Box>
      <Typography variant="h4">License information</Typography>
      <Typography>
        By using or processing this program you agree to abide by the terms of
        license agreement.
      </Typography>

      <Box sx={{ p: 1 }}></Box>
      <Typography variant="h4">Patent Information</Typography>
      <Typography>
        This product is covered by U.S. Patent Numbers 9571793, 9595396,
        9436700, 9210200, 9160969, 8988486, 8982173, 8736662, 8689313, 8605132,
        8593994, 8516050, 8471890, 8458283, 8412773, 8395652, 8319816, 8208000,
        8144632, 8121990, 8095409, 8023437, 7949116, 7885330, 7826401, 7701884,
        7613137, 7571210 and 7567270 as well as additional patents and pending
        patent applications.
      </Typography>

      <Box sx={{ p: 2 }}></Box>
      <Box>
        <Link underline="hover" onClick={handleRedirect}>
          <Typography align="center" variant="h4" color="#1472ee">
            Additional software used
          </Typography>
        </Link>
      </Box>

      <Box sx={{ p: 2 }}></Box>
      <Box>
        <Link
          underline="hover"
          href="https://visionable.com/agreement/"
          target="_blank"
        >
          <Typography align="center" variant="h4" color="#1472ee">
            Terms and conditions
          </Typography>
        </Link>
      </Box>

      <Box sx={{ p: 2 }}></Box>
      <Box>
        <Typography align="center" color="gray">
          Coppyright 2020 - 2022 Visionable Ltd | All Rights Reserved
        </Typography>
      </Box>
    </Stack>
  );
};
export default SettingsAbout;
