///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONIBLE FOR CHANGING PASSWORD
///
///////////////////////////////////////////////////////////////

// ROUTER
import { useRouter } from "next/router";

// MUI COMPONENTS
import { Stack, Button, Box } from "@mui/material";

///////////////////////////////////////////////////////////////
///
///  PASSWORD COMPONENT
///
///////////////////////////////////////////////////////////////

const Password = () => {
  //ROUTER
  const router = useRouter();

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTION SECTION
  ///
  ///////////////////////////////////////////////////////////////
  const handleChangePassword = () => {
    router.push("/admin/privacy");
  };
  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <Stack>
      <Box>
        <Button
          onClick={handleChangePassword}
          size="small"
          variant="contained"
          color="secondary"
        >
          Change password
        </Button>
      </Box>
    </Stack>
  );
};

export default Password;
