import { useContext } from "react";
import { ChatContext } from "@chat";

const _DisplayName = (id?: string) => {
  const chat = useContext(ChatContext);

  if (id) {
    if (chat.contacts[id]) {
      const contact = chat.contacts[id];
      return (
        contact?.display_name ||
        contact?.full_name ||
        contact?.name ||
        `${contact?.first_name} ${contact?.last_name}`
      );
    } else if (id === chat.currentUser?.user_id) {
      const currentUser = chat.currentUser;
      return (
        currentUser?.display_name ||
        currentUser?.full_name ||
        currentUser?.name ||
        `${currentUser?.first_name} ${currentUser?.last_name}`
      );
    }
  } else return "[No Name Available]";
};

export default _DisplayName;
