import { ChatContext } from "@chat";
import * as ChatTypes from "@chat/types";
import VisAvatar, { StatusBadge } from "@components/Avatar";
import _DisplayName from "@components/DisplayName";
import theme from "@components/VisionableTheme";
import { Close, Edit, Check } from "@mui/icons-material";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { userStore, utilityStore } from "@state/store";
import { useContext, useState, useEffect } from "react";
import Link from "@utilities/Link";

const PresenceSelect = () => {
  const { setProfileOpen, setShowStatusCard } = userStore();

  // Current User
  const chat = useContext(ChatContext);
  const [currentUser, setCurrentUser] = useState<ChatTypes.Contact | null>(
    null
  );

  useEffect(() => {
    setCurrentUser(chat.currentUser);
  }, [chat.currentUser]);

  const userID = currentUser?.user_id;
  const userName = _DisplayName(userID);
  const presence = currentUser?.presence;

  // selected presence
  const [selectedPresence, setSelectedPresence] = useState<ChatTypes.Presence>(
    chat.currentUser?.presence || "offline"
  );

  const setPresenceSelection = (selectedPresence: ChatTypes.Presence) =>
    setSelectedPresence(selectedPresence);

  const userSelectPresence = (selectedPresence: ChatTypes.Presence) =>
    setPresenceSelection(selectedPresence);

  // Update user presence on server
  useEffect(() => {
    if (!chat) return;

    chat.updatePresence(selectedPresence);
  }, [selectedPresence]);

  // log out
  const { setSignOutModalOpen } = utilityStore();
  const handleLogOut = async () => {
    setProfileOpen(false);
    setSignOutModalOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          p: 3,
        }}
      >
        <VisAvatar userID={userID} />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ ml: 2 }}>
            <Typography variant="h4">{userName}</Typography>
            <Typography>
              {presence &&
                presence
                  .replace(/-/g, " ")
                  .replace(/^./, (x) => x.toUpperCase())}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            ml: "auto",
            cursor: "pointer",
          }}
          onClick={() => setProfileOpen(false)}
        >
          <Close />
        </Box>
      </Box>
      <Box sx={{ px: 3 }}>
        <TextField
          fullWidth
          disabled
          size="small"
          placeholder="Set status message"
          sx={{
            "& .MuiOutlinedInput-root.Mui-disabled": {
              background: theme.palette.grey[200],
              cursor: "pointer",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${theme.palette.grey[200]}`,
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() => setShowStatusCard(true)}
                position="end"
              >
                <Edit />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <List
        dense={true}
        sx={{
          mb: 8,
          "& .MuiListItem-root": {
            cursor: "pointer",
            p: 0.25,
            pl: 6,
            pr: 3,
          },
          "& .MuiTypography-root": {
            display: "flex",
            alignItems: "center",
            minHeight: 26,
          },
          "&.MuiList-root .Mui-selected": {
            background: "transparent",
            "& .MuiTypography-root": {
              fontWeight: "bold",
            },
            "& .MuiTypography-root .MuiSvgIcon-root": {
              ml: 1,
            },
          },
        }}
      >
        <ListItem
          selected={selectedPresence === "available"}
          onClick={() => setPresenceSelection("available")}
        >
          <StatusBadge size="med" status="available" />
          <ListItemText sx={{ ml: 2 }}>
            Available
            {selectedPresence === "available" && <Check />}
          </ListItemText>
        </ListItem>
        <ListItem
          selected={selectedPresence === "do-not-disturb"}
          onClick={() => setPresenceSelection("do-not-disturb")}
        >
          <StatusBadge size="med" status="do-not-disturb" />
          <ListItemText sx={{ ml: 2 }}>
            Do not disturb
            {selectedPresence === "do-not-disturb" && <Check />}
          </ListItemText>
        </ListItem>
        <ListItem
          selected={selectedPresence === "busy"}
          onClick={() => setPresenceSelection("busy")}
        >
          <StatusBadge size="med" status="busy" />
          <ListItemText sx={{ ml: 2 }}>
            Busy
            {selectedPresence === "busy" && <Check />}
          </ListItemText>
        </ListItem>
        <ListItem
          selected={selectedPresence === "away"}
          onClick={() => setPresenceSelection("away")}
        >
          <StatusBadge size="med" status="away" />
          <ListItemText sx={{ ml: 2 }}>
            Away
            {selectedPresence === "away" && <Check />}
          </ListItemText>
        </ListItem>
      </List>
      <Link
        href="/#logout"
        onClick={handleLogOut}
        sx={{ textDecoration: "none" }}
      >
        <Box
          component="footer"
          sx={{
            p: 2,
            borderTop: `2px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Typography>Log out</Typography>
        </Box>
      </Link>
    </>
  );
};

export default PresenceSelect;
