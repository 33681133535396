import { useContext, useEffect, useState } from "react";
import { userStore } from "@state/store";
import { ChatContext } from "@chat";
import { Popover, useTheme } from "@mui/material";
import _DisplayName from "@components/DisplayName";
import VisAvatar from "@components/Avatar";
import { Contact } from "@chat/types";
import PopoverContent from "./PopoverContent";

const UserProfileAvatar = () => {
  const theme = useTheme();

  // Current User
  const chat = useContext(ChatContext);
  const [currentUser, setCurrentUser] = useState<Contact | null>(null);

  useEffect(() => {
    setCurrentUser(chat.currentUser);
  }, [chat.currentUser]);

  const userID = currentUser?.user_id;

  // open popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { profileOpen, setProfileOpen, setShowStatusCard } = userStore();

  const openUserProfile = (event: React.MouseEvent<HTMLElement>) => {
    setShowStatusCard(false);
    setAnchorEl(event.currentTarget);
    setProfileOpen(true);
  };

  const closeUserProfile = () => {
    setAnchorEl(null);
    setProfileOpen(false);
  };

  return (
    <>
      <VisAvatar
        userID={userID}
        onClick={openUserProfile}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      />
      <Popover
        open={profileOpen}
        onClose={closeUserProfile}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <PopoverContent />
      </Popover>
    </>
  );
};

export default UserProfileAvatar;
