import styles from "./index.module.css";

import { LanguageSwitcher } from "next-export-i18n";

const LocaleState = () => {
  return (
    <>
      <div className={styles.localeContainer}>
        <nav>
          <LanguageSwitcher lang="en"> en</LanguageSwitcher>
          <LanguageSwitcher lang="de"> de</LanguageSwitcher>
          <LanguageSwitcher lang="es"> es</LanguageSwitcher>
          <LanguageSwitcher lang="fr"> fr</LanguageSwitcher>
        </nav>
      </div>
    </>
  );
};

export default LocaleState;
