import { SetStateAction, useContext, useState } from "react";
import {
  Stack,
  Autocomplete,
  TextField,
  Box,
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material";
import { useRouter } from "next/router";
import { ChatContext } from "@chat";
import Avatar from "@components/Avatar";
import SearchField from "@components/SearchField";
import { Contact } from "@chat/types";

const SearchApp = () => {
  const router = useRouter();
  const route = router.pathname;

  const [searchValue, setSearchValue] = useState("");

  /// CONTACTS SEARCH
  const chat = useContext(ChatContext);
  const roster = Object.keys(chat.contacts)
    .map((key) => chat.contacts[key])
    .sort((a, b) => {
      // sort alphabetically
      const firstName = a.display_name.toLowerCase();
      const secondName = b.display_name.toLowerCase();
      if (firstName < secondName) return -1;
      if (firstName > secondName) return 1;
      return 0;
    })
    .sort((a, b) => {
      // sort by contact
      const isFirstContact = a.contact;
      const isSecondContact = b.contact;
      if (isFirstContact && !isSecondContact) return -1;
      if (!isFirstContact && isSecondContact) return 1;
      return 0;
    });

  const contactsFilterOptions = (
    options: Contact[],
    { inputValue }: { inputValue: string }
  ) => {
    const filtered = options.filter((option) => {
      const name = option.display_name;
      const email = option.email;
      const str = `${name} ${email}`.toLowerCase();
      return str.includes(inputValue.toLocaleLowerCase());
    });
    // only show first 6 items in options list
    return filtered.slice(0, 6);
  };

  const contactsGetOptionLabel = (option: Contact) =>
    `${option.display_name} ${option.email}`;

  const contactsGroupBy = (option: Contact) =>
    !option.contact ? "Directory" : "Contact";

  const contactsRenderOption = (
    props: {},
    option: Contact,
    { selected }: { selected: boolean }
  ) => (
    <li className={selected ? "selected" : ""} {...props}>
      <Avatar userID={option.user_id} />
      <Box sx={{ ml: 2 }}>{option.display_name}</Box>
    </li>
  );
  /// END CONTACTS SEARCH

  return (
    <Stack spacing={2} sx={{ width: "100%", maxWidth: 500 }}>
      <Autocomplete
        id="search-component"
        inputValue={searchValue}
        onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
        onChange={(event, reason) => console.log(event, reason)}
        renderInput={(params) => <TextField {...params} placeholder="Search" />}
        // open={!!search.length}
        open={searchValue.length >= 3}
        options={roster}
        filterOptions={contactsFilterOptions}
        getOptionLabel={contactsGetOptionLabel}
        groupBy={contactsGroupBy}
        renderOption={contactsRenderOption}
      />
    </Stack>
  );
};

export default SearchApp;
