////////////////////////////////////////////////////////////////
//                  REQUESTS - AUTHENTICATION SECTION
////////////////////////////////////////////////////////////////

import { getCurrentUserInfo } from "../../@cognito";
import * as Sentry from "@sentry/nextjs";
import { OtherUsers } from "../../types";
import * as Types from "../../types";
import { signal } from "@tensorflow/tfjs";

// SETTINGS OBJECT CONTROLING LEVEL OF ERROR REPORTING
const settings = {
  ENVIRONMENT: process.env.NODE_ENV,
  DEBUG: true,
  SENTRY: true,
};

////////////////////////////////////////////////////////////////
//                   GET SECTION
////////////////////////////////////////////////////////////////

/**
 * Return Cognito user pool
 * @return Response or error message from server
 */

export const getUserPool = async (
  page: number = 1
): Promise<Types.OtherUsers[]> => {
  const userInfo = await getCurrentUserInfo();

  try {
    if (!userInfo) throw new Error("Can't load user information");

    const credentials = {
      username: userInfo.username,
      password: userInfo.signInUserSession.idToken.jwtToken,
    };

    const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/users?page=${page}&per_page=300`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: credentials.password,
      },
    });
    if (res.ok) {
      const json = await res.json();
      const newUsers = json.data;
      if (json.current_page === json.last_page) {
        return newUsers;
      }

      return [...newUsers, ...(await getUserPool(page + 1))];
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error: any) {
    if (settings.DEBUG && settings.ENVIRONMENT === "development") {
      console.error(error);
    }

    if (settings.SENTRY) {
      Sentry.captureException(error);
    }
    throw error;
  }
};

/**
 * Get account specific MFA information
 * @return Response or error message from server
 */

export const getAccountMfaPreferences = async () => {
  const userInfo = await getCurrentUserInfo();

  const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/users/${userInfo.username}/mfa`;
  try {
    if (!userInfo) throw new Error("Can't load user information");

    const credentials = {
      username: userInfo.username,
      password: userInfo.signInUserSession.idToken.jwtToken,
    };

    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: credentials.password,
      },
    });

    if (res.ok) {
      return await res.json();
    } else {
      throw new Error(`${res.status} ${res.statusText}`);
    }
  } catch (error) {
    console.log(error);
  }
};
