///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONIBLE FOR SETING AND CONTROLING A TIMER
///
///////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";

// MUI COMPONENTS
import { Box, Typography } from "@mui/material";

type CountdownTimer = {
  label: string;
  reset: boolean;
  seconds: number;
  setTrigger: (trigger: boolean) => void;
};
///////////////////////////////////////////////////////////////
///
///  COUNTDOWN TIMER COMPONENT
///
///////////////////////////////////////////////////////////////

export const Counter = ({
  seconds,
  setTrigger,
  reset,
  label,
}: CountdownTimer) => {
  //STATE
  const [counter, setCounter] = useState(seconds);

  ///////////////////////////////////////////////////////////////
  ///
  /// LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  // TRIGGERS WHEN COUNTDOWN ENDS
  useEffect(() => {
    if (counter === 0) {
      setTrigger(true);
    }
  }, [counter]);

  // RESET TIMER
  useEffect(() => {
    if (reset) {
      setCounter(seconds);
    }
  }, [reset]);

  ///////////////////////////////////////////////////////////////
  ///
  /// STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  return (
    <Box>
      {counter === 0 ? (
        <Typography> </Typography>
      ) : (
        <Typography>
          {label}: {counter} seconds
        </Typography>
      )}
    </Box>
  );
};
