///////////////////////////////////////////////////////////////
///
///  COMPONEDNT THAT CHECKS AUTHENTICATION ON ROUTE CHANGE
///
///////////////////////////////////////////////////////////////

import { useEffect } from "react";
import { useRouter } from "next/router";
import { Auth } from "aws-amplify";
import { authStore } from "@state/store";

const withAuth = (Component: any) => {
  const checkAuth = (props: any) => {
    //ROUTER
    const router = useRouter();
    // GLOBAL STATE
    const { isSignedIn, setIsSignedIn, setValidSession } = authStore();

    ///////////////////////////////////////////////////////////////
    ///
    /// FUNCTIONS SECTION
    ///
    ///////////////////////////////////////////////////////////////

    const validateAuth = () => {
      Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
        .then((cognitoUser) => {
          cognitoUser.getSession((err: any, result: any) => {
            if (!err) {
              setValidSession(result.isValid());
              setIsSignedIn(true);
            } else {
              setValidSession(false);
              setIsSignedIn(false);
              router.push("/authentication");
            }
          });
        })
        .catch((err: any) => {
          setValidSession(false);
          setIsSignedIn(false);
          router.push("/authentication");
        });
    };

    ///////////////////////////////////////////////////////////////
    ///
    /// LIFECYCLE SECTION
    ///
    ///////////////////////////////////////////////////////////////

    useEffect(() => {
      try {
        validateAuth();
      } catch (error) {
        console.log(error);
      }
    }, [isSignedIn]);

    return <Component {...props} />;
  };

  return checkAuth;
};

export default withAuth;
