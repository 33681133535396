///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONIBLE FOR MFA ENFORCMENT BASED ON
///  ACCOUNT PREFERENCES
///
///////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";

// AMPLIFY AUTH HOOK
import { Auth } from "aws-amplify";

// MUI COMPONENTS
import theme from "@components/VisionableTheme";
import {
  Alert,
  Box,
  Button,
  Stack,
  Typography,
  Chip,
  Paper,
  Divider,
  Fade,
  Backdrop,
  TextField,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

//AMPLIFY UI
import { Image } from "@aws-amplify/ui-react";

//REQUESTS MODULE
import { getAccountMfaPreferences } from "@requests/Authentication";

// QR CODE GENERATOR LIBRARY
import QRCode from "react-qr-code";

// GLOBAL STATE
import { authStore, utilityStore } from "@state/store";

//COGNITO FUNCTIONS
import { getCurrentUserInfo } from "@cognito";

//OTHER COMPONENTS
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

//CUSTOM COMPONENTS
import SnackbarComponent from "@components/Snackbar";
import { Counter } from "@components/Countdown";

///////////////////////////////////////////////////////////////
///
///  DIALOG MFA COMPONENT
///
///////////////////////////////////////////////////////////////

export const DialogMFA = () => {
  //GLOBAL STATE
  const { setIsMfaRequired } = authStore();
  const { signOutModalOpen, setSignOutModalOpen } = utilityStore();
  //STATE
  const [accountMfaRequirement, setAccountMfaRequirement] = useState("");
  const [componentState, setComponentState] = useState("");
  const [accountModifieadByAdmin, setAccountModifieadByAdmin] =
    useState<boolean>(false);

  // ERROR
  const [error, setError] = useState<Error>();
  const [exceededAttemptsError, setExceededAttemptsError] = useState<Error>();

  // ALERTS
  const [notification, setNotification] = useState(false);
  const [nofificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState<
    "success" | "info" | "warning" | "error"
  >("success");

  //Fields
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verifying, setVerifying] = useState<boolean>(false);
  const [qr, setQr] = useState<string | null>(null);
  const [token, setToken] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState<number>(0);

  //SMS TIMER SATE
  const [resendTimer, setResendTimer] = useState<number>(60);
  const [reset, setReset] = useState<boolean>(false);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTION SECTION
  ///
  //////////////////////////////////////////////////////////////

  // TOGGLE INTERNAL STATE CHANGES  AND RESET VARIABLES
  const handleComponentStateChange = (event: React.SyntheticEvent) => {
    setError(undefined);
    // WHEN MFA TYPE IS ANY
    if (componentState === "SMS" && accountMfaRequirement === "ANY") {
      setPhoneNumber("");
      setToken("");
      setError(undefined);
      setComponentState("CHOICE");
      setVerifying(false);
    }
    if (componentState === "AUTHENTICATOR" && accountMfaRequirement === "ANY") {
      setToken("");
      setComponentState("CHOICE");
      setError(undefined);
    }
    // WHEN MFA TYPE IS SPECIFIED
    if (componentState === "SMS" && accountMfaRequirement === "SMS") {
      setPhoneNumber("");
      setToken("");
      setError(undefined);
      setComponentState("SET");
      setVerifying(false);
    }
    if (
      componentState === "AUTHENTICATOR" &&
      accountMfaRequirement === "AUTHENTICATOR"
    ) {
      setToken("");
      setComponentState("SET");
      setError(undefined);
    }
    // RETURN TO LOGOOUT
    if (componentState === "CHOICE" || componentState === "SET") {
      setSignOutModalOpen(true);
    }
  };

  // HANDLE TOKEN INPUT CHANGE
  const onTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    setToken(e.target.value);
  };
  // HANDLE PHONE NUMBER INPUT CHANGE
  const onNumberChange = (number: string) => {
    setError(undefined);
    setPhoneNumber(number);
  };

  // HANDLE RESET CODE CHANGE
  const handleResendCode = () => {
    try {
      Auth.currentAuthenticatedUser().then((user) => {
        setResendEnabled(false);
        setResendTimer(60);
        setReset(true);
        Auth.verifyUserAttribute(user, "phone_number")
          .then(() => {
            ///console.log("MFA DATA", "RESEND");
          })
          .catch((e) => {
            //console.log("MFA DATA", "RESEND FAILED", e);
          });
      });
    } catch (error) {
      // console.log(error);
    }
  };

  // INITIATES THE PROCESS OF VERIFICATION FOR (AUTHENTICATOR APP) MFA
  const setupTOTP = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const {
      attributes: { email },
    } = user;

    const code = await Auth.setupTOTP(user);
    const issuer = "Visionable";
    const url = `otpauth://totp/Visionable:${email}?secret=${code}&issuer=${issuer}`;
    setQr(url);
  };
  // HANDLE DISPLAYING NOTIFICATIONS
  const processNotification = (
    message: string,
    type: "success" | "info" | "warning" | "error"
  ) => {
    setNotificationMessage(message);
    setNotificationType(type);
    setNotification(true);
  };

  // INITIATES THE PROCESS OF PHONE NUMBER VERIFICATION FOR SMS MFA
  const setupSMS = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const status = await Auth.updateUserAttributes(user, {
        phone_number: "+" + phoneNumber,
      });

      if (status == "SUCCESS") {
        setVerifying(true);
        Auth.verifyUserAttribute(user, "phone_number")
          .then((res) => {
            processNotification("Check your phone for code", "info");
          })
          .catch((error) => {
            errorHandlerSms(error);
          });
      }
    } catch (error: any) {
      errorHandlerSms(error);
      setComponentState("SMS");
    }
  };

  // VALIDATE TOKEN AND ENABLE 2FA FOR USER
  const validateToken = async () => {
    const user = await Auth.currentAuthenticatedUser();
    switch (componentState) {
      case "SMS":
        Auth.verifyCurrentUserAttributeSubmit("phone_number", token)
          .then((res) => {
            Auth.setPreferredMFA(user, "SMS");
            processNotification("Successfully enabled MFA via SMS", "success");
            setOpen(false);
            setVerifying(false);
            setPhoneNumber("");
            setToken("");
          })
          .catch((error) => {
            errorHandlerSms(error);
          });
        break;
      case "AUTHENTICATOR":
        Auth.verifyTotpToken(user, token)
          .then(() => {
            Auth.setPreferredMFA(user, "TOTP");
            processNotification(
              "Successfully enabled MFA via Authenticator app",
              "success"
            );

            setVerifying(false);
            setOpen(false);
            setToken("");
          })
          .catch((error) => {
            errorHandlerAuthenticator(error);
          });
        break;
    }
  };

  // RESET MFA FOR USER IF ADMIN SETTINGS CHANGED
  const resetMFA = async () => {
    const user = await Auth.currentAuthenticatedUser();
    Auth.setPreferredMFA(user, "NOMFA");
    setOpen(false);
  };

  // CHECKS ACCOUNT LEVEL PREFERENCES
  const enforceMFA = async () => {
    let status = "";
    const mfaPreferances = {
      userMFA: "",
      accountMFA: "",
    };
    const accountPreferances = await getAccountMfaPreferences();

    // SET GLOBAL MFA REQUIRED FLAG
    setIsMfaRequired(accountPreferances.mfa_required);

    // NORMALIZE MFA PREFERENCES TO CORESPONd TO GOGNITO TYPES
    switch (accountPreferances.account_mfa_type) {
      case "none":
        mfaPreferances.accountMFA = "NOMFA";
        break;
      case "sms":
        mfaPreferances.accountMFA = "SMS";
        break;
      case "app":
        mfaPreferances.accountMFA = "AUTHENTICATOR";
        break;
      case "both":
        mfaPreferances.accountMFA = "ANY";
        break;
    }
    switch (accountPreferances.user_mfa_type) {
      case "none":
        mfaPreferances.userMFA = "NOMFA";
        break;
      case "sms":
        mfaPreferances.userMFA = "SMS";
        break;
      case "app":
        mfaPreferances.userMFA = "AUTHENTICATOR";
        break;
      case "both":
        mfaPreferances.userMFA = "ANY";
        break;
    }

    // MFA VALIDATION LOGIC
    if (
      mfaPreferances.userMFA == "NOMFA" &&
      mfaPreferances.accountMFA == "NOMFA"
    ) {
      status = "valid";
    } else if (
      mfaPreferances.userMFA == "SMS" &&
      (mfaPreferances.accountMFA == "SMS" || mfaPreferances.accountMFA == "ANY")
    ) {
      status = "valid";
    } else if (
      mfaPreferances.userMFA == "AUTHENTICATOR" &&
      (mfaPreferances.accountMFA == "AUTHENTICATOR" ||
        mfaPreferances.accountMFA == "ANY")
    ) {
      status = "valid";
    } else if (
      mfaPreferances.userMFA == "NOMFA" &&
      (mfaPreferances.accountMFA == "SMS" || mfaPreferances.accountMFA == "ANY")
    ) {
      status = "prompt";
    } else if (
      mfaPreferances.userMFA == "NOMFA" &&
      (mfaPreferances.accountMFA == "AUTHENTICATOR" ||
        mfaPreferances.accountMFA == "ANY")
    ) {
      status = "prompt";
    } else if (
      mfaPreferances.userMFA == "SMS" &&
      mfaPreferances.accountMFA == "AUTHENTICATOR"
    ) {
      await resetMFA();
      setAccountModifieadByAdmin(true);
      status = "prompt";
    } else if (
      mfaPreferances.userMFA == "AUTHENTICATOR" &&
      mfaPreferances.accountMFA == "SMS"
    ) {
      await resetMFA();
      setAccountModifieadByAdmin(true);
      status = "prompt";
    } else {
      status = "invalid";
    }

    return { status, mfaPreferances };
  };

  // HANDLE INPUT ERRORS
  const errorHandlerAuthenticator = (error: any) => {
    if (error.code === "InvalidParameterException") {
      setError(new Error("Invalid code"));
    }
    if (error.code === "EnableSoftwareTokenMFAException") {
      setError(new Error("Invalid code"));
    }
  };

  const errorHandlerSms = (error: any) => {
    if (error.code === "InvalidParameterException") {
      setError(new Error("Number is not valid "));
    }
    if (error.code === "CodeMismatchException") {
      setError(new Error("Invalid code"));
    }
    if (error.code === "LimitExceededException") {
      setExceededAttemptsError(
        new Error("Attempt limit exceeded, please try after some time")
      );
    }
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  useEffect(() => {
    (async () => {
      const mfaPreferances = {
        accountMFA: "",
      };
      //const user = await getCurrentUserInfo();
      const accountPreferances = await getAccountMfaPreferences();

      const mfaStatus = await enforceMFA();
      if (mfaStatus.status == "prompt") {
        setOpen(true);

        switch (accountPreferances.account_mfa_type) {
          case "none":
            mfaPreferances.accountMFA = "NOMFA";
            break;
          case "sms":
            mfaPreferances.accountMFA = "SMS";
            setComponentState("SET");
            break;
          case "app":
            mfaPreferances.accountMFA = "AUTHENTICATOR";
            setComponentState("SET");
            setupTOTP();
            break;
          case "both":
            mfaPreferances.accountMFA = "ANY";
            setComponentState("CHOICE");
            setupTOTP();
            break;
        }
        setAccountMfaRequirement(mfaPreferances.accountMFA);
      }
      ///TESTING
      //console.log(mfaPreferances, accountPreferances);
      // setOpen(true);
      // setAccountMfaRequirement("ANY");
      // setComponentState("CHOICE");
    })();
  }, []);

  // GENERATE QR CODE WHEN MODAL INITIALIZES THE VIEW
  useEffect(() => {
    if (
      accountMfaRequirement === "AUTHENTICATOR" ||
      accountMfaRequirement === "ANY"
    ) {
      setupTOTP();
    }
  }, [accountMfaRequirement]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  TESTING SECTION
  ///
  ///////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   const setMfaPreferences = async (mfa: any) => {
  //     // " null, "sms", "app", "both""
  //     const userInfo = await getCurrentUserInfo();
  //     const url = `${process.env.NEXT_PUBLIC_API_BASE}/api/accounts/${process.env.NEXT_PUBLIC_ACCOUNT_ID}`;
  //     try {
  //       if (!userInfo) throw new Error("Can't load user information");
  //       const body = {
  //         account_id: process.env.NEXT_PUBLIC_ACCOUNT_ID,
  //         account_mfa: mfa,
  //       };
  //       const credentials = {
  //         username: userInfo.username,
  //         password: userInfo.signInUserSession.idToken.jwtToken,
  //       };
  //       const res = await fetch(url, {
  //         method: "PUT",
  //         headers: {
  //           Authorization: credentials.password,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(body),
  //       });
  //       if (res.ok) {
  //         const json = await res.json();
  //         console.log("test", json);
  //       } else {
  //         throw new Error(`${res.status} ${res.statusText}`);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   setMfaPreferences(null);
  //   getAccountMfaPreferences().then((data) => {
  //     console.log("MFA DATA", data);
  //   });
  // }, []);

  // useEffect(() => {
  //   console.log("accountMfaRequirement", accountMfaRequirement);
  //   console.log("comonentState", componentState);
  // }, [componentState]);

  // useEffect(() => {
  //   console.log("ERROR", error);
  // }, [error]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  switch (componentState) {
    case "SMS":
      return (
        <>
          <Backdrop
            sx={{
              overflow: "auto",
              color: "#fff",
              opacity: "100%",
              zIndex: 9000,
            }}
            open={open}
          >
            <SnackbarComponent
              message={nofificationMessage}
              vertical={"bottom"}
              horizontal={"right"}
              severity={notificationType}
              open={notification}
              setOpen={setNotification}
            />
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                height: "100%",
                minHeight: { xs: "100%", sm: "100%", md: "70%" },
                borderRadius: "0px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<ArrowBackIosIcon fontSize="small" />}
                sx={{ position: "absolute", left: "1%", top: "2%" }}
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  handleComponentStateChange(e);
                }}
              >
                Back
              </Button>
              <Fade in={true}>
                <Stack
                  p={2}
                  sx={{
                    width: "100%",
                    maxWidth: "400px",
                    margin: "auto",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box p={1}>
                    <Image
                      alt="Visionable logo"
                      src="/images/visionable-login-logo.svg"
                    />
                  </Box>
                  <Stack spacing={2}>
                    <Typography variant="h2">SMS</Typography>
                    <Typography sx={{ lineHeight: "120%" }} variant="body1">
                      To enable Multi-factor Authentication please verify your
                      mobile number, then enter the verification code to
                      activate.
                    </Typography>{" "}
                    {verifying ? (
                      <>
                        <Typography variant="h4">Enter your code</Typography>
                        <Stack>
                          <TextField
                            error={error && true}
                            type={"number"}
                            size="small"
                            placeholder="Enter your code"
                            value={token}
                            onChange={onTokenChange}
                          />

                          {error ? (
                            <Typography color="red" variant="body2">
                              {error?.message}
                            </Typography>
                          ) : null}
                        </Stack>
                        {exceededAttemptsError ? (
                          <Typography color="red" variant="body2">
                            {exceededAttemptsError.message}
                          </Typography>
                        ) : (
                          <Counter
                            label="Resend available in "
                            reset={reset}
                            seconds={resendTimer}
                            setTrigger={setResendEnabled}
                          />
                        )}

                        <Stack direction={"row"} spacing={1}>
                          <Button
                            size="small"
                            sx={{ width: "100%" }}
                            color="secondary"
                            variant="text"
                            disabled={!resendEnabled}
                            onClick={handleResendCode}
                          >
                            Resend code
                          </Button>
                          <Button
                            size="small"
                            sx={{ width: "100%" }}
                            color="secondary"
                            variant="contained"
                            disabled={
                              !token || exceededAttemptsError != undefined
                            }
                            onClick={validateToken}
                          >
                            Activate
                          </Button>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <Stack spacing={1}>
                          <Typography variant="h4">
                            Enter your phone number
                          </Typography>
                          <Stack>
                            <PhoneInput
                              country={"us"}
                              preferredCountries={["us", "gb"]}
                              value={phoneNumber}
                              placeholder="Phone number"
                              onChange={(phone) => onNumberChange(phone)}
                              inputStyle={{
                                width: "100%",
                                height: "40px",
                                borderRadius: "4px",
                                borderWidth: "1px",
                                borderColor: error != undefined ? "red" : "",
                                // boxShadow:
                                //   error != undefined
                                //     ? `0px 0px 1px 1px red `
                                //     : "lightgray",
                              }}
                            />
                            {error ? (
                              <Typography color="red" variant="body2">
                                {error.message}
                              </Typography>
                            ) : null}
                          </Stack>
                        </Stack>

                        <Button
                          size="small"
                          disabled={!phoneNumber}
                          color="secondary"
                          sx={{ width: "100%" }}
                          variant="contained"
                          onClick={setupSMS}
                        >
                          Enter
                        </Button>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Fade>
            </Paper>
          </Backdrop>
        </>
      );
    case "AUTHENTICATOR":
      return (
        <>
          <Backdrop
            sx={{
              overflow: "auto",
              color: "#fff",
              opacity: "100%",
              zIndex: 9000,
            }}
            open={open}
          >
            <SnackbarComponent
              message={nofificationMessage}
              vertical={"bottom"}
              horizontal={"right"}
              severity={notificationType}
              open={notification}
              setOpen={setNotification}
            />
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                height: "100%",
                minHeight: { xs: "100%", sm: "100%", md: "70%" },
                borderRadius: "0px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<ArrowBackIosIcon fontSize="small" />}
                sx={{ position: "absolute", left: "1%", top: "2%" }}
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  handleComponentStateChange(e);
                }}
              >
                Back
              </Button>
              <Fade in={true}>
                <Stack
                  p={2}
                  sx={{
                    width: "100%",
                    maxWidth: "420px",
                    margin: "auto",

                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box p={1}>
                    <Image
                      alt="Visionable logo"
                      src="/images/visionable-login-logo.svg"
                    />
                  </Box>
                  <Stack spacing={2} p={2}>
                    <Typography variant="h2">Authentication app</Typography>
                    <Typography sx={{ lineHeight: "120%" }} variant="body1">
                      To enable Multi-factor Authentication you need to download
                      an authentication app like: Google Authenticator
                      compatible app (Authy, FreeOTP, Google Authenticator,
                      Microsoft Authenticator, LastPass Authenticator) and scan
                      the barcode below. Then enter the one time password to
                      activate.
                    </Typography>
                    <Box
                      p={2}
                      sx={{ justifySelf: "center", alignSelf: "center" }}
                    >
                      <QRCode value={qr || ""}> </QRCode>
                    </Box>
                    <Typography variant="h4">Enter code</Typography>
                    <Stack>
                      <TextField
                        error={error && true}
                        type={"number"}
                        size="small"
                        placeholder="Enter your code"
                        onChange={onTokenChange}
                      />
                      {error ? (
                        <Typography color="red" variant="body2">
                          {error.message}
                        </Typography>
                      ) : null}
                    </Stack>

                    <Button
                      size="small"
                      sx={{ width: "100%" }}
                      color="secondary"
                      variant="contained"
                      disabled={!token}
                      onClick={validateToken}
                    >
                      Verify
                    </Button>
                  </Stack>
                </Stack>
              </Fade>
            </Paper>
          </Backdrop>
        </>
      );
    case "CHOICE":
      return (
        <>
          <Backdrop
            sx={{
              overflow: "auto",
              color: "#fff",
              opacity: "100%",
              zIndex: 9000,
            }}
            open={open}
          >
            <SnackbarComponent
              message={nofificationMessage}
              vertical={"bottom"}
              horizontal={"right"}
              severity={notificationType}
              open={notification}
              setOpen={setNotification}
            />
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                height: "100%",
                minHeight: { xs: "100%", sm: "100%", md: "70%" },
                borderRadius: "0px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<ArrowBackIosIcon fontSize="small" />}
                sx={{ position: "absolute", left: "1%", top: "2%" }}
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  handleComponentStateChange(e);
                }}
              >
                Back
              </Button>

              <Stack
                p={2}
                sx={{
                  width: "100%",
                  maxWidth: "420px",
                  margin: "auto",

                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Box p={1}>
                  <Image
                    alt="Visionable logo"
                    src="/images/visionable-login-logo.svg"
                  />
                </Box>
                <Stack spacing={2}>
                  <Typography variant="h2">2 factor authentication</Typography>
                  <Typography sx={{ lineHeight: "120%" }} variant="body1">
                    To help keep your account safe, you are required to enable
                    multi-factor authentication.
                  </Typography>
                  <Typography variant="h4">
                    Choose how you want to sign in:
                  </Typography>
                  <Stack spacing={2}>
                    <Stack
                      onClick={() => setComponentState("SMS")}
                      spacing={1}
                      direction={"row"}
                      justifyContent="start"
                      alignItems={"center"}
                    >
                      <Chip label="1" color="secondary" />
                      <Typography variant="body1" color="primary">
                        Receive an SMS
                      </Typography>
                    </Stack>
                    <Divider variant="inset" />

                    <Stack
                      onClick={() => setComponentState("AUTHENTICATOR")}
                      spacing={1}
                      direction={"row"}
                      justifyContent="start"
                      alignItems={"center"}
                    >
                      <Chip label="2" color="secondary" />
                      <Typography variant="body1" color="primary">
                        Use an authenticator app
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Backdrop>
        </>
      );

    case "SET":
      return (
        <>
          <Backdrop
            sx={{
              overflow: "auto",
              color: "#fff",
              opacity: "100%",
              zIndex: 9000,
            }}
            open={open}
          >
            <Paper
              elevation={0}
              sx={{
                width: "100%",
                height: "100%",
                minHeight: { xs: "100%", sm: "100%", md: "70%" },
                borderRadius: "0px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<ArrowBackIosIcon fontSize="small" />}
                sx={{ position: "absolute", left: "1%", top: "2%" }}
                variant="outlined"
                color="secondary"
                onClick={(e) => {
                  handleComponentStateChange(e);
                }}
              >
                Back
              </Button>

              <Stack
                p={2}
                sx={{
                  width: "100%",
                  maxWidth: "420px",
                  margin: "auto",

                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Box p={1}>
                  <Image
                    alt="Visionable logo"
                    src="/images/visionable-login-logo.svg"
                  />
                </Box>
                <Stack spacing={2}>
                  <Typography variant="h2">
                    Multi-factor authentication
                  </Typography>

                  {accountModifieadByAdmin ? (
                    <Alert
                      sx={{
                        color: "black",
                        alignItems: "center",
                      }}
                      variant="filled"
                      color="info"
                      severity="info"
                    >
                      You are seeing this because account administrator has
                      modified multi-factor authentication settings please
                      adjust your account.
                    </Alert>
                  ) : null}
                  <Typography sx={{ lineHeight: "120%" }} variant="body1">
                    To help keep your account safe, you are required to enable
                    multi-factor authentication via{" "}
                    {accountMfaRequirement === "SMS"
                      ? "Sms"
                      : "Authenticator app"}
                  </Typography>
                  <Typography variant="h4">
                    Choose how you want to sign in:
                  </Typography>
                  <Button
                    sx={{ width: "100%" }}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setComponentState(accountMfaRequirement);
                    }}
                  >
                    Continue
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </Backdrop>
        </>
      );

    default:
      return null;
  }
};
