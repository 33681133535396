///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONIBLE FOR ACTIVATING/DEACTIVATING MFA
///
///////////////////////////////////////////////////////////////

import { useState, useEffect } from "react";

// AMPLIFY AUTH HOOK
import { Auth } from "aws-amplify";

//GLOBAL STATE
import { authStore } from "@state/store";

// MUI COMPONENTS
import {
  Stack,
  TextField,
  Box,
  Radio,
  Button,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Switch,
  Typography,
  Alert,
} from "@mui/material";

// QR CODE GENERATOR LIBRARY
import QRCode from "react-qr-code";

//CUSTOM COMPONENTS
import SnackbarComponent from "@components/Snackbar";

//OTHER COMPONENTS
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

///////////////////////////////////////////////////////////////
///
///  MFA COMPONENT
///
///////////////////////////////////////////////////////////////

const Mfa = () => {
  // GLOBAL STATE
  const { isMfaRequired } = authStore();
  //STATE
  const [checked, setChecked] = useState<boolean>(false);
  const [mfaType, setMfaType] = useState<string>("NOMFA");
  const [qr, setQr] = useState<string | null>(null);
  const [token, setToken] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [activated, setActivated] = useState<boolean>(false);

  // ERROR
  const [error, setError] = useState<Error>();
  const [errorPhone, setErrorPhone] = useState<Error>();
  const [exceededAttemptsError, setExceededAttemptsError] = useState<Error>();

  // ALERTS
  const [notification, setNotification] = useState(false);
  const [nofificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState<
    "success" | "info" | "warning" | "error"
  >("success");

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTION SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // HANDLE TOKEN INPUT CHANGE
  const onTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    setToken(e.target.value);
  };
  // HANDLE PHONE NUMBER INPUT CHANGE
  const onNumberChange = (number: string) => {
    setErrorPhone(undefined);
    setPhoneNumber(number);
  };

  // HANDLE SWITCH STATE CHANGE
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (event.target.checked === true) {
          setChecked(false);
          Auth.setPreferredMFA(user, "NOMFA");
          setMfaType("NOMFA");
          setPhoneNumber("");
          setToken("");
          setError(undefined);
          setErrorPhone(undefined);
          setActivated(false);
        } else {
          setChecked(true);
        }
      })
      .catch((e) => console.log(e));
  };

  // SELECT TYPE WHEN MFA ENABLED
  const handleSelectType = (event: React.ChangeEvent<HTMLInputElement>) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setMfaType((event.target as HTMLInputElement).value);
        if (event.target.value === "SMS") {
          null;
        } else if (event.target.value === "TOTP") {
          setupTOTP();
        } else {
          Auth.setPreferredMFA(user, "NOMFA");
        }
      })
      .catch((e) => console.log(e));
  };

  // INITIATES THE PROCESS OF VERIFICATION FOR (AUTHENTICATOR APP) MFA
  const setupTOTP = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const {
      attributes: { email },
    } = user;

    const code = await Auth.setupTOTP(user);
    const issuer = "Visionable";
    const url = `otpauth://totp/Visionable:${email}?secret=${code}&issuer=${issuer}`;
    setQr(url);
  };

  // INITIATES THE PROCESS OF PHONE NUMBER VERIFICATION FOR SMS MFA
  const setupSMS = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const status = await Auth.updateUserAttributes(user, {
        phone_number: "+" + phoneNumber,
      });
      if (status == "SUCCESS") {
        Auth.verifyUserAttribute(user, "phone_number")
          .then((res) => {
            processNotification("Check your phone for code", "info");
          })
          .catch((error) => {
            setPhoneNumber("");
            errorHandlerPhoneField(error);
          });
      }
    } catch (error) {
      errorHandlerPhoneField(error);
    }
  };

  // VALIDATE TOKEN AND ENABLE 2FA FOR USER
  const validateToken = async () => {
    const user = await Auth.currentAuthenticatedUser();

    switch (mfaType) {
      case "SMS":
        Auth.verifyCurrentUserAttributeSubmit("phone_number", token)
          .then((res) => {
            Auth.setPreferredMFA(user, "SMS");
            setActivated(true);
            processNotification("Successfully enabled MFA", "success");
          })
          .catch((error) => {
            errorHandlerSms(error);
          });
        break;
      case "TOTP":
        Auth.verifyTotpToken(user, token)
          .then(() => {
            Auth.setPreferredMFA(user, "TOTP");
            setActivated(true);
            processNotification("Successfully enabled MFA", "success");

            setQr(null);
          })
          .catch((error) => {
            errorHandlerAuthenticator(error);
          });
        break;
    }
  };

  // HANDLE INPUT ERRORS
  const errorHandlerAuthenticator = (error: any) => {
    if (error.code === "InvalidParameterException") {
      setError(new Error("Invalid code"));
    }
    if (error.code === "EnableSoftwareTokenMFAException") {
      setError(new Error("Invalid code"));
    }
  };

  const errorHandlerSms = (error: any) => {
    if (error.code === "InvalidParameterException") {
      setError(new Error("Number is not valid "));
    }
    if (error.code === "CodeMismatchException") {
      setError(new Error("Invalid code"));
    }
    if (error.code === "ExpiredCodeException") {
      setError(new Error("Invalid code"));
    }
    if (error.code === "LimitExceededException") {
      setExceededAttemptsError(
        new Error("Attempt limit exceeded, please try after some time")
      );
    }
  };

  const errorHandlerPhoneField = (error: any) => {
    if (error.code === "InvalidParameterException") {
      setErrorPhone(new Error("Number is not valid "));
    }
    if (error.code === "CodeMismatchException") {
      setErrorPhone(new Error("Invalid code"));
    }
    if (error.code === "LimitExceededException") {
      setExceededAttemptsError(
        new Error("Attempt limit exceeded, please try after some time")
      );
    }
  };

  // HANDLE DISPLAYING NOTIFICATIONS
  const processNotification = (
    message: string,
    type: "success" | "info" | "warning" | "error"
  ) => {
    setNotificationMessage(message);
    setNotificationType(type);
    setNotification(true);
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // SET INITIAL MFA TYPE BASED ON COGNITO USER SETTING
  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const mfaType = await Auth.getPreferredMFA(user);
      if (mfaType === "NOMFA") {
        setChecked(false);
      } else {
        setChecked(true);
        setMfaType(mfaType);
      }
    })();
  }, []);

  // RESET ERRORS
  useEffect(() => {
    setError(undefined);
  }, []);

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <Stack>
      <SnackbarComponent
        message={nofificationMessage}
        vertical={"bottom"}
        horizontal={"right"}
        severity={notificationType}
        open={notification}
        setOpen={setNotification}
      />
      <Stack p={1} spacing={1}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                sx={{ marginRight: "10px" }}
                color="secondary"
                checked={checked}
                onChange={handleChange}
                disabled={isMfaRequired}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              checked
                ? "Disable multi-factor authentication "
                : "Enable multi-factor authentication "
            }
          />
        </FormGroup>
      </Stack>
      <Stack>
        {checked && mfaType === "NOMFA" ? (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={mfaType}
              onChange={handleSelectType}
            >
              <FormControlLabel
                value="TOTP"
                control={<Radio />}
                label="Authentication app "
              />
              <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
            </RadioGroup>
          </FormControl>
        ) : null}
      </Stack>
      {qr && checked && mfaType === "TOTP" && activated === false ? (
        <Stack direction={"column"}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={mfaType}
            onChange={handleSelectType}
          >
            <FormControlLabel
              value="TOTP"
              control={<Radio />}
              label="Authentication app "
            />
          </RadioGroup>

          <Stack
            sx={{
              height: "100%",
              justifyContent: "center",
              alignItems: "space-between",
            }}
          >
            <Stack
              sx={{
                width: { sx: "100%", sm: "70%" },
                alignItems: "space-between",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ lineHeight: "120%" }} variant="body1">
                To enable Multi Factor Authentication you need to download a
                Google Authenticator compatible app (Authy, FreeOTP, Google
                Authenticator, Microsoft Authenticator, LastPass Authenticator)
                and scan the barcode. Then enter the one time password to
                activate.
              </Typography>
              <Box
                mt={2}
                mb={2}
                sx={{ justifySelf: "left", alignSelf: "left" }}
              >
                <QRCode value={qr || ""}> </QRCode>
              </Box>
              <Stack direction={"row"} spacing={1}>
                <Box width="70%">
                  <TextField
                    sx={{ borderWidth: "2px !important" }}
                    error={error && true}
                    fullWidth={true}
                    type={"number"}
                    size="small"
                    placeholder="Enter code"
                    onChange={onTokenChange}
                  />
                  {error ? (
                    <Typography color="red" variant="body2">
                      {error.message}
                    </Typography>
                  ) : null}
                </Box>

                <Box width="30%">
                  <Button
                    fullWidth={true}
                    size="small"
                    color="secondary"
                    variant="contained"
                    disabled={!token}
                    onClick={validateToken}
                  >
                    Verify
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : null}

      {checked && mfaType === "SMS" && activated === false ? (
        <Stack direction={"column"}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={mfaType}
            onChange={handleSelectType}
          >
            <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
          </RadioGroup>
          <Stack
            spacing={2}
            sx={{
              width: { sx: "100%", sm: "70%" },
              alignItems: "space-between",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ lineHeight: "120%" }} variant="body1">
              To enable Multi Factor Authentication you need to input your phone
              number and verify the code.
            </Typography>{" "}
            <Stack direction={"row"} spacing={1}>
              <Box width="70%">
                <Stack>
                  <PhoneInput
                    country={"us"}
                    preferredCountries={["us", "gb"]}
                    value={phoneNumber}
                    placeholder="Phone number"
                    onChange={(phone) => onNumberChange(phone)}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "4px",
                      borderWidth: "1px",
                      borderColor: errorPhone != undefined ? "red" : "",
                    }}
                  />
                  {errorPhone ? (
                    <Typography color="red" variant="body2">
                      {errorPhone.message}
                    </Typography>
                  ) : null}
                </Stack>
              </Box>
              <Box width="30%">
                <Button
                  fullWidth={true}
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={setupSMS}
                >
                  Submit
                </Button>
              </Box>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <Box width="70%">
                <TextField
                  error={error && true}
                  sx={{ borderWidth: "2px !important" }}
                  size="small"
                  fullWidth={true}
                  onChange={onTokenChange}
                  placeholder="Verification code"
                />
                {error ? (
                  <Typography color="red" variant="body2">
                    {error?.message}
                  </Typography>
                ) : null}
              </Box>

              <Box width="30%">
                <Button
                  fullWidth={true}
                  size="small"
                  color="secondary"
                  variant="contained"
                  disabled={!token}
                  onClick={validateToken}
                >
                  Activate
                </Button>
              </Box>
            </Stack>
            <Box>
              {exceededAttemptsError ? (
                <Typography color="red" variant="body2">
                  {exceededAttemptsError.message}
                </Typography>
              ) : null}
            </Box>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default Mfa;
