import { ChevronLeft, Edit } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { userStore } from "@state/store";
import { ChangeEvent, useState } from "react";

const CustomStatusMessage = ({
  message,
  index,
}: {
  message?: string;
  index: string;
}) => {
  const { selectedCustomStatus, setSelectedCustomStatus } = userStore();
  const [currentMessage, setCurrentMessage] = useState(message);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCurrentMessage(event.target.value);

  const handleSelectedOption = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setSelectedCustomStatus(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Radio
        checked={selectedCustomStatus === index}
        onChange={handleSelectedOption}
        value={index}
        color="secondary"
        name="custom-status-message"
      />
      <TextField
        size="small"
        placeholder="Add new message"
        value={currentMessage}
        onChange={handleChange}
        fullWidth
        disabled={
          !(selectedCustomStatus === index) || currentMessage === "None"
        }
        InputProps={{
          endAdornment: !(selectedCustomStatus === index) &&
            currentMessage !== "None" &&
            !!currentMessage?.length && (
              <InputAdornment position="end">
                <Edit />
              </InputAdornment>
            ),
        }}
        sx={{
          "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
            border: "transparent",
          },
        }}
      />
    </Box>
  );
};

const StatusMessages = () => {
  const { setShowStatusCard } = userStore();

  return (
    <Box sx={{ pb: 6 }}>
      <Box sx={{ display: "flex", p: 3 }}>
        <Box>
          <IconButton onClick={() => setShowStatusCard(false)}>
            <ChevronLeft />
          </IconButton>
        </Box>
        <Box sx={{ ml: 2 }}>
          <Typography variant="h3">Status message</Typography>
          <Typography>Enter custom status' below</Typography>
        </Box>
      </Box>
      <FormControl sx={{ width: "100%", px: 3 }}>
        <RadioGroup defaultValue="none">
          {["None", "", "Saved Message #1", ""].map((message, index) => {
            return (
              <Box key={`_${index}`}>
                <CustomStatusMessage message={message} index={`${index}`} />
              </Box>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default StatusMessages;
