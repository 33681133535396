///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONIBLE DISPLAYING LOGOUT CONFIRMATION
///
///////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";

//ROUTER
import { useRouter } from "next/router";

// MUI COMPONENTS
import { Box, Button, Stack, Typography, Paper, Backdrop } from "@mui/material";

// GLOBAL STATE
import { authStore, meetingsStore, utilityStore } from "@state/store";

//COGNITO
import { signOut } from "@cognito";

///////////////////////////////////////////////////////////////
///
///  DIALOG LOGOUT COMPONENT
///
///////////////////////////////////////////////////////////////

export const LogoutConfirmation = () => {
  //GLOBAL STATE
  const { setIsSignedIn } = authStore();
  const { resetMeetingsStore } = meetingsStore();
  const { signOutModalOpen, setSignOutModalOpen } = utilityStore();

  //ROUTER
  const router = useRouter();

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTION SECTION
  ///
  //////////////////////////////////////////////////////////////
  const handleCancel = () => {
    setSignOutModalOpen(false);
  };

  const handleLogout = async () => {
    await signOut()
      .then(() => {
        setIsSignedIn(false);
        localStorage.removeItem("CognitoIdentityServiceProvider");
        sessionStorage.removeItem("CognitoIdentityServiceProvider");
        resetMeetingsStore();
        setSignOutModalOpen(false);
        router.push("/authentication");
      })
      .catch((e) => console.log("signed out"));
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <>
      <Backdrop
        sx={{
          overflow: "auto",
          color: "#fff",
          opacity: "100%",
          zIndex: 10000,
        }}
        open={signOutModalOpen}
      >
        <Paper
          elevation={1}
          sx={{
            borderRadius: "10px",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Box pl={6} pr={6} pt={2} pb={2}>
            <Typography p={1} variant="h3" align="center">
              Log out
            </Typography>
            <Typography p={1} variant="body1" align="center">
              Are you sure you want to log out?
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box p={1}>
                <Button variant="outlined" color="error" onClick={handleCancel}>
                  Cancel
                </Button>
              </Box>
              <Box p={1}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                >
                  Continue
                </Button>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Backdrop>
    </>
  );
};
