import DialogContent from "@mui/material/DialogContent";
import SettingsContent from "./SettingsContent";
import { utilityStore } from "@state/store";
import { StyledDialog, StyledDialogTitle } from "@components/DialogModal";

const SettingsDialog = () => {
  const { settingsDialogOpen, setSettingsDialogOpen } = utilityStore();

  const toggleSettingsDialog = () => {
    setSettingsDialogOpen(!settingsDialogOpen);
  };

  return (
    <StyledDialog
      fullWidth
      maxWidth={"md"}
      onClose={toggleSettingsDialog}
      open={settingsDialogOpen}
      sx={{ m: 0, p: 2 }}
    >
      <StyledDialogTitle
        id="styled-dialog-title"
        onClose={toggleSettingsDialog}
      >
        Settings
      </StyledDialogTitle>
      <DialogContent
        sx={{
          /** remove padding from dialog content */
          "&.MuiDialogContent-root": { padding: "0px" },
        }}
      >
        <SettingsContent />
      </DialogContent>
    </StyledDialog>
  );
};

export default SettingsDialog;
