const en = require("../@locale/en");
const fr = require("../@locale/fr");
const de = require("../@locale/de");
const es = require("../@locale/es");
// import en from "../@locale/en";
// import fr from "../@locale/fr";
// import de from "../@locale/de";
// import es from "../@locale/es";

const i18n = {
  translations: {
    en,
    fr,
    de,
    es,
  },

  defaultLang: "en",
  useBrowserDefault: true,
};

module.exports = i18n;
